.section_1 {
  /* background: radial-gradient(circle, #24246e, #06051f); */
  /* bg-gradient-to-r from-[#4568dc] to-[#b06ab3] */

  position: relative;

  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
}

.section_1_item,
.section_1_item::before,
.section_1_item::after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.9;
  transform-origin: top;
  transition: all 5s linear 0s;
}

.purple {
  -webkit-animation: purple linear 30s alternate infinite;
  animation: purple linear 30s alternate infinite;
  border: 2px solid #241379;
  border-radius: 100%;
  width: 26px;
  height: 26px;
  transform: translate3d(81vw, 24vh, 0);
  z-index: 9;
}

.purple::before {
  -webkit-animation: purple-pseudo linear 15s alternate infinite;
  animation: purple-pseudo linear 15s alternate infinite;
  background: #241379;
  border: 2px solid #241379;
  width: 14px;
  height: 19px;
  transform: translate3d(-39vw, -24vh, 0) rotate(311deg);
}

.purple::after {
  -webkit-animation: purple-pseudo linear 20s alternate infinite;
  animation: purple-pseudo linear 20s alternate infinite;
  border: 2px solid #241379;
  width: 36px;
  height: 49px;
  transform: translate3d(-23vw, 51vh, 0) rotate(71deg);
}

@-webkit-keyframes purple {
  50% {
    transform: translate3d(84vw, 54vh, 0);
  }
  100% {
    transform: translate3d(21vw, 3vh, 0);
  }
}

@keyframes purple {
  50% {
    transform: translate3d(84vw, 54vh, 0);
  }
  100% {
    transform: translate3d(21vw, 3vh, 0);
  }
}
@-webkit-keyframes purple-pseudo {
  33% {
    transform: translate3d(-19vw, 40vh, 0) rotate(341deg);
  }
  100% {
    transform: translate3d(76vw, 47vh, 0) rotate(134deg);
  }
}
@keyframes purple-pseudo {
  33% {
    transform: translate3d(-19vw, 40vh, 0) rotate(341deg);
  }
  100% {
    transform: translate3d(76vw, 47vh, 0) rotate(134deg);
  }
}
.medium-blue {
  -webkit-animation: medium-blue linear 30s alternate infinite;
  animation: medium-blue linear 30s alternate infinite;
  border: 2px solid #2185bf;
  border-radius: 100%;
  width: 35px;
  height: 35px;
  transform: translate3d(28vw, 40vh, 0);
  z-index: 9;
}

.medium-blue::before {
  -webkit-animation: medium-blue-pseudo linear 15s alternate infinite;
  animation: medium-blue-pseudo linear 15s alternate infinite;
  background: #2185bf;
  border: 2px solid #2185bf;
  width: 8px;
  height: 30px;
  transform: translate3d(49vw, -5vh, 0) rotate(279deg);
}

.medium-blue::after {
  -webkit-animation: medium-blue-pseudo linear 20s alternate infinite;
  animation: medium-blue-pseudo linear 20s alternate infinite;
  border: 2px solid #2185bf;
  width: 42px;
  height: 9px;
  transform: translate3d(35vw, -29vh, 0) rotate(39deg);
}

@-webkit-keyframes medium-blue {
  50% {
    transform: translate3d(57vw, 21vh, 0);
  }
  100% {
    transform: translate3d(2vw, 76vh, 0);
  }
}

@keyframes medium-blue {
  50% {
    transform: translate3d(57vw, 21vh, 0);
  }
  100% {
    transform: translate3d(2vw, 76vh, 0);
  }
}
@-webkit-keyframes medium-blue-pseudo {
  33% {
    transform: translate3d(-57vw, 17vh, 0) rotate(75deg);
  }
  100% {
    transform: translate3d(7vw, -44vh, 0) rotate(51deg);
  }
}
@keyframes medium-blue-pseudo {
  33% {
    transform: translate3d(-57vw, 17vh, 0) rotate(75deg);
  }
  100% {
    transform: translate3d(7vw, -44vh, 0) rotate(51deg);
  }
}
.light-blue {
  -webkit-animation: light-blue linear 30s alternate infinite;
  animation: light-blue linear 30s alternate infinite;
  border: 2px solid #1fbce1;
  border-radius: 100%;
  width: 8px;
  height: 8px;
  transform: translate3d(89vw, 67vh, 0);
  z-index: 5;
}

.light-blue::before {
  -webkit-animation: light-blue-pseudo linear 15s alternate infinite;
  animation: light-blue-pseudo linear 15s alternate infinite;
  background: #1fbce1;
  border: 2px solid #1fbce1;
  width: 48px;
  height: 39px;
  transform: translate3d(-74vw, -24vh, 0) rotate(78deg);
}

.light-blue::after {
  -webkit-animation: light-blue-pseudo linear 20s alternate infinite;
  animation: light-blue-pseudo linear 20s alternate infinite;
  border: 2px solid #1fbce1;
  width: 22px;
  height: 49px;
  transform: translate3d(-52vw, -52vh, 0) rotate(316deg);
}

@-webkit-keyframes light-blue {
  50% {
    transform: translate3d(91vw, 5vh, 0);
  }
  100% {
    transform: translate3d(9vw, 6vh, 0);
  }
}

@keyframes light-blue {
  50% {
    transform: translate3d(91vw, 5vh, 0);
  }
  100% {
    transform: translate3d(9vw, 6vh, 0);
  }
}
@-webkit-keyframes light-blue-pseudo {
  33% {
    transform: translate3d(-20vw, 26vh, 0) rotate(213deg);
  }
  100% {
    transform: translate3d(67vw, 80vh, 0) rotate(5deg);
  }
}
@keyframes light-blue-pseudo {
  33% {
    transform: translate3d(-20vw, 26vh, 0) rotate(213deg);
  }
  100% {
    transform: translate3d(67vw, 80vh, 0) rotate(5deg);
  }
}
.red {
  -webkit-animation: red linear 30s alternate infinite;
  animation: red linear 30s alternate infinite;
  border: 2px solid #b62f56;
  border-radius: 100%;
  width: 12px;
  height: 12px;
  transform: translate3d(32vw, 92vh, 0);
  z-index: 3;
}

.red::before {
  -webkit-animation: red-pseudo linear 15s alternate infinite;
  animation: red-pseudo linear 15s alternate infinite;
  background: #b62f56;
  border: 2px solid #b62f56;
  width: 7px;
  height: 33px;
  transform: translate3d(-4vw, -76vh, 0) rotate(50deg);
}

.red::after {
  -webkit-animation: red-pseudo linear 20s alternate infinite;
  animation: red-pseudo linear 20s alternate infinite;
  border: 2px solid #b62f56;
  width: 38px;
  height: 7px;
  transform: translate3d(-26vw, -2vh, 0) rotate(239deg);
}

@-webkit-keyframes red {
  50% {
    transform: translate3d(88vw, 14vh, 0);
  }
  100% {
    transform: translate3d(17vw, 44vh, 0);
  }
}

@keyframes red {
  50% {
    transform: translate3d(88vw, 14vh, 0);
  }
  100% {
    transform: translate3d(17vw, 44vh, 0);
  }
}
@-webkit-keyframes red-pseudo {
  33% {
    transform: translate3d(-49vw, 15vh, 0) rotate(83deg);
  }
  100% {
    transform: translate3d(18vw, -38vh, 0) rotate(360deg);
  }
}
@keyframes red-pseudo {
  33% {
    transform: translate3d(-49vw, 15vh, 0) rotate(83deg);
  }
  100% {
    transform: translate3d(18vw, -38vh, 0) rotate(360deg);
  }
}
.orange {
  -webkit-animation: orange linear 30s alternate infinite;
  animation: orange linear 30s alternate infinite;
  border: 2px solid #d5764c;
  border-radius: 100%;
  width: 39px;
  height: 39px;
  transform: translate3d(70vw, 13vh, 0);
  z-index: 12;
}

.orange::before {
  -webkit-animation: orange-pseudo linear 15s alternate infinite;
  animation: orange-pseudo linear 15s alternate infinite;
  background: #d5764c;
  border: 2px solid #d5764c;
  width: 34px;
  height: 39px;
  transform: translate3d(-34vw, 53vh, 0) rotate(107deg);
}

.orange::after {
  -webkit-animation: orange-pseudo linear 20s alternate infinite;
  animation: orange-pseudo linear 20s alternate infinite;
  border: 2px solid #d5764c;
  width: 39px;
  height: 18px;
  transform: translate3d(21vw, 17vh, 0) rotate(314deg);
}

@-webkit-keyframes orange {
  50% {
    transform: translate3d(90vw, 90vh, 0);
  }
  100% {
    transform: translate3d(89vw, 72vh, 0);
  }
}

@keyframes orange {
  50% {
    transform: translate3d(90vw, 90vh, 0);
  }
  100% {
    transform: translate3d(89vw, 72vh, 0);
  }
}
@-webkit-keyframes orange-pseudo {
  33% {
    transform: translate3d(-43vw, -48vh, 0) rotate(49deg);
  }
  100% {
    transform: translate3d(-52vw, -48vh, 0) rotate(189deg);
  }
}
@keyframes orange-pseudo {
  33% {
    transform: translate3d(-43vw, -48vh, 0) rotate(49deg);
  }
  100% {
    transform: translate3d(-52vw, -48vh, 0) rotate(189deg);
  }
}
.yellow {
  -webkit-animation: yellow linear 30s alternate infinite;
  animation: yellow linear 30s alternate infinite;
  border: 2px solid #ffd53e;
  border-radius: 100%;
  width: 38px;
  height: 38px;
  transform: translate3d(50vw, 56vh, 0);
  z-index: 9;
}

.yellow::before {
  -webkit-animation: yellow-pseudo linear 15s alternate infinite;
  animation: yellow-pseudo linear 15s alternate infinite;
  background: #ffd53e;
  border: 2px solid #ffd53e;
  width: 27px;
  height: 13px;
  transform: translate3d(15vw, 30vh, 0) rotate(115deg);
}

.yellow::after {
  -webkit-animation: yellow-pseudo linear 20s alternate infinite;
  animation: yellow-pseudo linear 20s alternate infinite;
  border: 2px solid #ffd53e;
  width: 7px;
  height: 24px;
  transform: translate3d(-6vw, 13vh, 0) rotate(4deg);
}

@-webkit-keyframes yellow {
  50% {
    transform: translate3d(73vw, 96vh, 0);
  }
  100% {
    transform: translate3d(67vw, 38vh, 0);
  }
}

@keyframes yellow {
  50% {
    transform: translate3d(73vw, 96vh, 0);
  }
  100% {
    transform: translate3d(67vw, 38vh, 0);
  }
}
@-webkit-keyframes yellow-pseudo {
  33% {
    transform: translate3d(-72vw, -72vh, 0) rotate(267deg);
  }
  100% {
    transform: translate3d(5vw, 60vh, 0) rotate(234deg);
  }
}
@keyframes yellow-pseudo {
  33% {
    transform: translate3d(-72vw, -72vh, 0) rotate(267deg);
  }
  100% {
    transform: translate3d(5vw, 60vh, 0) rotate(234deg);
  }
}
.cyan {
  -webkit-animation: cyan linear 30s alternate infinite;
  animation: cyan linear 30s alternate infinite;
  border: 2px solid #78ffba;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  transform: translate3d(17vw, 65vh, 0);
  z-index: 9;
}

.cyan::before {
  -webkit-animation: cyan-pseudo linear 15s alternate infinite;
  animation: cyan-pseudo linear 15s alternate infinite;
  background: #78ffba;
  border: 2px solid #78ffba;
  width: 29px;
  height: 23px;
  transform: translate3d(12vw, 11vh, 0) rotate(146deg);
}

.cyan::after {
  -webkit-animation: cyan-pseudo linear 20s alternate infinite;
  animation: cyan-pseudo linear 20s alternate infinite;
  border: 2px solid #78ffba;
  width: 11px;
  height: 35px;
  transform: translate3d(-15vw, 3vh, 0) rotate(293deg);
}

@-webkit-keyframes cyan {
  50% {
    transform: translate3d(49vw, 61vh, 0);
  }
  100% {
    transform: translate3d(40vw, 37vh, 0);
  }
}

@keyframes cyan {
  50% {
    transform: translate3d(49vw, 61vh, 0);
  }
  100% {
    transform: translate3d(40vw, 37vh, 0);
  }
}
@-webkit-keyframes cyan-pseudo {
  33% {
    transform: translate3d(-1vw, -6vh, 0) rotate(206deg);
  }
  100% {
    transform: translate3d(25vw, -36vh, 0) rotate(317deg);
  }
}
@keyframes cyan-pseudo {
  33% {
    transform: translate3d(-1vw, -6vh, 0) rotate(206deg);
  }
  100% {
    transform: translate3d(25vw, -36vh, 0) rotate(317deg);
  }
}
.light-green {
  -webkit-animation: light-green linear 30s alternate infinite;
  animation: light-green linear 30s alternate infinite;
  border: 2px solid #98fd85;
  border-radius: 100%;
  width: 13px;
  height: 13px;
  transform: translate3d(41vw, 9vh, 0);
  z-index: 4;
}

.light-green::before {
  -webkit-animation: light-green-pseudo linear 15s alternate infinite;
  animation: light-green-pseudo linear 15s alternate infinite;
  background: #98fd85;
  border: 2px solid #98fd85;
  width: 25px;
  height: 18px;
  transform: translate3d(-35vw, 76vh, 0) rotate(97deg);
}

.light-green::after {
  -webkit-animation: light-green-pseudo linear 20s alternate infinite;
  animation: light-green-pseudo linear 20s alternate infinite;
  border: 2px solid #98fd85;
  width: 12px;
  height: 13px;
  transform: translate3d(52vw, 84vh, 0) rotate(247deg);
}

@-webkit-keyframes light-green {
  50% {
    transform: translate3d(11vw, 99vh, 0);
  }
  100% {
    transform: translate3d(61vw, 60vh, 0);
  }
}

@keyframes light-green {
  50% {
    transform: translate3d(11vw, 99vh, 0);
  }
  100% {
    transform: translate3d(61vw, 60vh, 0);
  }
}
@-webkit-keyframes light-green-pseudo {
  33% {
    transform: translate3d(-1vw, -49vh, 0) rotate(6deg);
  }
  100% {
    transform: translate3d(-10vw, 27vh, 0) rotate(162deg);
  }
}
@keyframes light-green-pseudo {
  33% {
    transform: translate3d(-1vw, -49vh, 0) rotate(6deg);
  }
  100% {
    transform: translate3d(-10vw, 27vh, 0) rotate(162deg);
  }
}
.lime {
  -webkit-animation: lime linear 30s alternate infinite;
  animation: lime linear 30s alternate infinite;
  border: 2px solid #befb46;
  border-radius: 100%;
  width: 28px;
  height: 28px;
  transform: translate3d(78vw, 98vh, 0);
  z-index: 7;
}

.lime::before {
  -webkit-animation: lime-pseudo linear 15s alternate infinite;
  animation: lime-pseudo linear 15s alternate infinite;
  background: #befb46;
  border: 2px solid #befb46;
  width: 41px;
  height: 22px;
  transform: translate3d(0vw, -94vh, 0) rotate(254deg);
}

.lime::after {
  -webkit-animation: lime-pseudo linear 20s alternate infinite;
  animation: lime-pseudo linear 20s alternate infinite;
  border: 2px solid #befb46;
  width: 31px;
  height: 29px;
  transform: translate3d(21vw, -25vh, 0) rotate(279deg);
}

@-webkit-keyframes lime {
  50% {
    transform: translate3d(85vw, 79vh, 0);
  }
  100% {
    transform: translate3d(97vw, 100vh, 0);
  }
}

@keyframes lime {
  50% {
    transform: translate3d(85vw, 79vh, 0);
  }
  100% {
    transform: translate3d(97vw, 100vh, 0);
  }
}
@-webkit-keyframes lime-pseudo {
  33% {
    transform: translate3d(-14vw, -47vh, 0) rotate(237deg);
  }
  100% {
    transform: translate3d(-85vw, -38vh, 0) rotate(219deg);
  }
}
@keyframes lime-pseudo {
  33% {
    transform: translate3d(-14vw, -47vh, 0) rotate(237deg);
  }
  100% {
    transform: translate3d(-85vw, -38vh, 0) rotate(219deg);
  }
}
.magenta {
  -webkit-animation: magenta linear 30s alternate infinite;
  animation: magenta linear 30s alternate infinite;
  border: 2px solid #6c046c;
  border-radius: 100%;
  width: 22px;
  height: 22px;
  transform: translate3d(78vw, 56vh, 0);
  z-index: 2;
}

.magenta::before {
  -webkit-animation: magenta-pseudo linear 15s alternate infinite;
  animation: magenta-pseudo linear 15s alternate infinite;
  background: #6c046c;
  border: 2px solid #6c046c;
  width: 34px;
  height: 22px;
  transform: translate3d(-41vw, 23vh, 0) rotate(282deg);
}

.magenta::after {
  -webkit-animation: magenta-pseudo linear 20s alternate infinite;
  animation: magenta-pseudo linear 20s alternate infinite;
  border: 2px solid #6c046c;
  width: 5px;
  height: 30px;
  transform: translate3d(-44vw, 20vh, 0) rotate(99deg);
}

@-webkit-keyframes magenta {
  50% {
    transform: translate3d(49vw, 67vh, 0);
  }
  100% {
    transform: translate3d(16vw, 82vh, 0);
  }
}

@keyframes magenta {
  50% {
    transform: translate3d(49vw, 67vh, 0);
  }
  100% {
    transform: translate3d(16vw, 82vh, 0);
  }
}
@-webkit-keyframes magenta-pseudo {
  33% {
    transform: translate3d(2vw, -43vh, 0) rotate(197deg);
  }
  100% {
    transform: translate3d(15vw, -45vh, 0) rotate(222deg);
  }
}
@keyframes magenta-pseudo {
  33% {
    transform: translate3d(2vw, -43vh, 0) rotate(197deg);
  }
  100% {
    transform: translate3d(15vw, -45vh, 0) rotate(222deg);
  }
}
.lightish-red {
  -webkit-animation: lightish-red linear 30s alternate infinite;
  animation: lightish-red linear 30s alternate infinite;
  border: 2px solid #f04c81;
  border-radius: 100%;
  width: 15px;
  height: 15px;
  transform: translate3d(14vw, 41vh, 0);
  z-index: 2;
}

.lightish-red::before {
  -webkit-animation: lightish-red-pseudo linear 15s alternate infinite;
  animation: lightish-red-pseudo linear 15s alternate infinite;
  background: #f04c81;
  border: 2px solid #f04c81;
  width: 25px;
  height: 26px;
  transform: translate3d(23vw, -11vh, 0) rotate(113deg);
}

.lightish-red::after {
  -webkit-animation: lightish-red-pseudo linear 20s alternate infinite;
  animation: lightish-red-pseudo linear 20s alternate infinite;
  border: 2px solid #f04c81;
  width: 14px;
  height: 14px;
  transform: translate3d(6vw, -41vh, 0) rotate(162deg);
}

@-webkit-keyframes lightish-red {
  50% {
    transform: translate3d(28vw, 96vh, 0);
  }
  100% {
    transform: translate3d(97vw, 57vh, 0);
  }
}

@keyframes lightish-red {
  50% {
    transform: translate3d(28vw, 96vh, 0);
  }
  100% {
    transform: translate3d(97vw, 57vh, 0);
  }
}
@-webkit-keyframes lightish-red-pseudo {
  33% {
    transform: translate3d(-11vw, -2vh, 0) rotate(150deg);
  }
  100% {
    transform: translate3d(-69vw, 17vh, 0) rotate(295deg);
  }
}
@keyframes lightish-red-pseudo {
  33% {
    transform: translate3d(-11vw, -2vh, 0) rotate(150deg);
  }
  100% {
    transform: translate3d(-69vw, 17vh, 0) rotate(295deg);
  }
}
.pink {
  -webkit-animation: pink linear 30s alternate infinite;
  animation: pink linear 30s alternate infinite;
  border: 2px solid #ff4293;
  border-radius: 100%;
  width: 12px;
  height: 12px;
  transform: translate3d(39vw, 82vh, 0);
  z-index: 10;
}

.pink::before {
  -webkit-animation: pink-pseudo linear 15s alternate infinite;
  animation: pink-pseudo linear 15s alternate infinite;
  background: #ff4293;
  border: 2px solid #ff4293;
  width: 17px;
  height: 13px;
  transform: translate3d(34vw, -9vh, 0) rotate(257deg);
}

.pink::after {
  -webkit-animation: pink-pseudo linear 20s alternate infinite;
  animation: pink-pseudo linear 20s alternate infinite;
  border: 2px solid #ff4293;
  width: 23px;
  height: 44px;
  transform: translate3d(0vw, -12vh, 0) rotate(255deg);
}

@-webkit-keyframes pink {
  50% {
    transform: translate3d(42vw, 59vh, 0);
  }
  100% {
    transform: translate3d(7vw, 65vh, 0);
  }
}

@keyframes pink {
  50% {
    transform: translate3d(42vw, 59vh, 0);
  }
  100% {
    transform: translate3d(7vw, 65vh, 0);
  }
}
@-webkit-keyframes pink-pseudo {
  33% {
    transform: translate3d(22vw, -16vh, 0) rotate(119deg);
  }
  100% {
    transform: translate3d(70vw, 7vh, 0) rotate(187deg);
  }
}
@keyframes pink-pseudo {
  33% {
    transform: translate3d(22vw, -16vh, 0) rotate(119deg);
  }
  100% {
    transform: translate3d(70vw, 7vh, 0) rotate(187deg);
  }
}

@media (max-width: 768px) {
  .purple {
    width: 18px;
    height: 18px;
  }
  .purple::before {
    width: 22px;
    height: 18px;
  }
  .purple::after {
    width: 3px;
    height: 11px;
  }

  .medium-blue {
    width: 15px;
    height: 15px;
  }

  .medium-blue::before {
    width: 16px;
    height: 8px;
  }

  .medium-blue:after {
    width: 20px;
    height: 5px;
  }

  .light-blue {
    width: 15px;
    height: 15px;
  }

  .light-blue::before {
    width: 9px;
    height: 9px;
  }

  .light-blue:after {
    width: 16px;
    height: 28px;
  }

  .red {
    width: 16px;
    height: 16px;
  }

  .red::before {
    width: 16px;
    height: 24px;
  }

  .red:after {
    width: 14px;
    height: 22px;
  }

  .orange {
    width: 10px;
    height: 10px;
  }

  .orange::before {
    width: 4px;
    height: 16px;
  }

  .orange:after {
    width: 24px;
    height: 17px;
  }

  .yellow {
    width: 16px;
    height: 16px;
  }

  .yellow::before {
    width: 20px;
    height: 6px;
  }

  .yellow:after {
    width: 24px;
    height: 4px;
  }

  .cyan {
    width: 20px;
    height: 20px;
  }

  .cyan::before {
    width: 25px;
    height: 5px;
  }

  .cyan:after {
    width: 12px;
    height: 5px;
  }

  .light-green {
    width: 22px;
    height: 22px;
  }

  .light-green::before {
    width: 18px;
    height: 25px;
  }

  .light-green:after {
    width: 27px;
    height: 27px;
  }

  .lime {
    width: 5px;
    height: 5px;
  }

  .lime::before {
    width: 21px;
    height: 5px;
  }

  .lime:after {
    width: 25px;
    height: 5px;
  }

  .magenta {
    width: 12px;
    height: 12px;
  }

  .magenta::before {
    width: 24px;
    height: 12px;
  }

  .magenta:after {
    width: 12px;
    height: 22px;
  }

  .lightish-red {
    width: 20px;
    height: 20px;
  }

  .lightish-red::before {
    width: 24px;
    height: 13px;
  }

  .lightish-red:after {
    width: 16px;
    height: 4px;
  }
  .pink {
    width: 16px;
    height: 16px;
  }

  .pink::before {
    width: 3px;
    height: 12px;
  }

  .pink:after {
    width: 16px;
    height: 19px;
  }
}

/* typing */
.typed-cursor {
  opacity: 1;
  animation: typedjsBlink 0.7s infinite;
  -webkit-animation: typedjsBlink 0.7s infinite;
  animation: typedjsBlink 0.7s infinite;
}
@keyframes typedjsBlink {
  50% {
    opacity: 0;
  }
}
@-webkit-keyframes typedjsBlink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.typed-fade-out {
  opacity: 0;
  transition: opacity 0.25s;
  -webkit-animation: 0;
  animation: 0;
}

/* .typed i {
  color: #ff6b6b;
} */

.text-stroke-hero {
  text-align: center;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#09f1b8),
    color-stop(#00a2ff),
    color-stop(#ff00d2),
    to(#fed90f)
  );
  background-image: -o-linear-gradient(
    left,
    #09f1b8,
    #00a2ff,
    #ff00d2,
    #fed90f
  );
  background-image: linear-gradient(
    to right,
    #09f1b8,
    #00a2ff,
    #ff00d2,
    #fed90f
  );
  color: #000119;
  letter-spacing: calc(1em / 8);
  padding: calc(--stroke-width / 2);
  -webkit-text-stroke-color: transparent;
  -webkit-text-stroke-width: calc(1em / 16);
}
