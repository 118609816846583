@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,900;1,900&family=Kanit:wght@900&family=Poppins:wght@900&display=swap");

.pattern-text {
  color: transparent;
  background: url(../../assets/images/8.jpg) no-repeat top center;
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  font-size: 10rem;
  line-height: 8rem;
  font-family: "Barlow", sans-serif;
  font-weight: 900;
}

.text-stroke {
  text-align: center;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#09f1b8),
    color-stop(#00a2ff),
    color-stop(#ff00d2),
    to(#fed90f)
  );
  background-image: -o-linear-gradient(
    left,
    #09f1b8,
    #00a2ff,
    #ff00d2,
    #fed90f
  );
  background-image: linear-gradient(
    to right,
    #09f1b8,
    #00a2ff,
    #ff00d2,
    #fed90f
  );
  color: #000119;
  font-size: 60px;
  font-weight: 700;
  letter-spacing: calc(1em / 8);
  padding: calc(--stroke-width / 2);
  -webkit-text-stroke-color: transparent;
  -webkit-text-stroke-width: calc(1em / 16);
}

@media (max-width: 768px) {
  .pattern-text {
    font-size: 7.5rem;
    line-height: 6rem;
  }

  .text-stroke {
    font-size: 40px;
  }
}
