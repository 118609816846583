#container {
  position: absolute;
  width: 100%;
  top: 10%;
  -webkit-perspective: 1000;
  -webkit-perspective-origin: 50% 620px;
}
#stage {
  width: 100%;
  height: 100%;
  -webkit-transition: -webkit-transform 2s;
  -webkit-transform-style: preserve-3d;
}

#shape {
  position: relative;
  top: 200px;
  margin: 0 auto;
  height: 200px;
  width: 200px;
  -webkit-transform-style: preserve-3d;
}

.plane {
  position: absolute;
  height: 200px;
  width: 200px;
  border: 1px solid white;
  -webkit-border-radius: 12px;
  -webkit-box-sizing: border-box;
  text-align: center;
  font-family: Times, serif;
  font-size: 124pt;
  color: black;
  background-color: rgba(255, 255, 255, 0.6);
  -webkit-transition: -webkit-transform 2s, opacity 2s;
  -webkit-backface-visibility: hidden;
}

#shape.backfaces .plane {
  -webkit-backface-visibility: visible;
}

#shape {
  animation: spin 8s infinite linear;
}

@keyframes spin {
  from {
    -webkit-transform: rotateY(0);
  }
  to {
    -webkit-transform: rotateY(360deg);
  }
}

.one {
  background-image: url(../../assets/images/1.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.two {
  background-image: url(../../assets/images/2.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.three {
  background-image: url(../../assets/images/3.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.four {
  background-image: url(../../assets/images/4.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.five {
  background-image: url(../../assets/images/5.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.six {
  background-image: url(../../assets/images/6.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.seven {
  background-image: url(../../assets/images/7.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.eight {
  background-image: url(../../assets/images/8.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.nine {
  background-image: url(../../assets/images/9.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.ten {
  background-image: url(../../assets/images/10.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.eleven {
  background-image: url(../../assets/images/11.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.twelve {
  background-image: url(../../assets/images/12.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

#shape.cube > .one {
  opacity: 0.2;
  -webkit-transform: scale3d(1.2, 1.2, 1.2) rotateX(90deg) translateZ(100px);
}

#shape.cube > .two {
  opacity: 0.2;
  -webkit-transform: scale3d(1.2, 1.2, 1.2) translateZ(100px);
}

#shape.cube > .three {
  opacity: 0.2;
  -webkit-transform: scale3d(1.2, 1.2, 1.2) rotateY(90deg) translateZ(100px);
}

#shape.cube > .four {
  opacity: 0.2;
  -webkit-transform: scale3d(1.2, 1.2, 1.2) rotateY(180deg) translateZ(100px);
}

#shape.cube > .five {
  opacity: 0.2;
  -webkit-transform: scale3d(1.2, 1.2, 1.2) rotateY(-90deg) translateZ(100px);
}

#shape.cube > .six {
  opacity: 0.2;
  -webkit-transform: scale3d(1.2, 1.2, 1.2) rotateX(-90deg) translateZ(100px)
    rotate(180deg);
}

#shape:hover.cube > .one {
  opacity: 0.9;
  -webkit-transform: scale3d(1.5, 1.5, 1.5) rotateX(90deg) translateZ(130px);
}

#shape:hover.cube > .two {
  opacity: 0.9;
  -webkit-transform: scale3d(1.5, 1.5, 1.5) translateZ(150px);
}

#shape:hover.cube > .three {
  opacity: 0.9;
  -webkit-transform: scale3d(1.5, 1.5, 1.5) rotateY(90deg) translateZ(150px);
}

#shape:hover.cube > .four {
  opacity: 0.9;
  -webkit-transform: scale3d(1.5, 1.5, 1.5) rotateY(180deg) translateZ(150px);
}

#shape:hover.cube > .five {
  opacity: 0.9;
  -webkit-transform: scale3d(1.5, 1.5, 1.5) rotateY(-90deg) translateZ(150px);
}

#shape:hover.cube > .six {
  opacity: 0.9;
  -webkit-transform: scale3d(1.5, 1.5, 1.5) rotateX(-90deg) translateZ(130px)
    rotate(180deg);
}

#shape.cube > .seven {
  -webkit-transform: scale3d(0.8, 0.8, 0.8) rotateX(90deg) translateZ(100px)
    rotate(180deg);
}

#shape.cube > .eight {
  -webkit-transform: scale3d(0.8, 0.8, 0.8) translateZ(100px);
}

#shape.cube > .nine {
  -webkit-transform: scale3d(0.8, 0.8, 0.8) rotateY(90deg) translateZ(100px);
}

#shape.cube > .ten {
  -webkit-transform: scale3d(0.8, 0.8, 0.8) rotateY(180deg) translateZ(100px);
}

#shape.cube > .eleven {
  -webkit-transform: scale3d(0.8, 0.8, 0.8) rotateY(-90deg) translateZ(100px);
}

#shape.cube > .twelve {
  -webkit-transform: scale3d(0.8, 0.8, 0.8) rotateX(-90deg) translateZ(100px);
}

.ring > .one {
  -webkit-transform: translateZ(380px);
}

.ring > .two {
  -webkit-transform: rotateY(30deg) translateZ(380px);
}

.ring > .three {
  -webkit-transform: rotateY(60deg) translateZ(380px);
}

.ring > .four {
  -webkit-transform: rotateY(90deg) translateZ(380px);
}

.ring > .five {
  -webkit-transform: rotateY(120deg) translateZ(380px);
}

.ring > .six {
  -webkit-transform: rotateY(150deg) translateZ(380px);
}

.ring > .seven {
  -webkit-transform: rotateY(180deg) translateZ(380px);
}

.ring > .eight {
  -webkit-transform: rotateY(210deg) translateZ(380px);
}

.ring > .nine {
  -webkit-transform: rotateY(-120deg) translateZ(380px);
}

.ring > .ten {
  -webkit-transform: rotateY(-90deg) translateZ(380px);
}

.ring > .eleven {
  -webkit-transform: rotateY(300deg) translateZ(380px);
}

.ring > .twelve {
  -webkit-transform: rotateY(330deg) translateZ(380px);
}

@media (max-width: 768px) {
  #container {
    transform: scale(0.6);
  }
}
