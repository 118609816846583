@import url("https://fonts.googleapis.com/css?family=Fuzzy+Bubbles");
@import url("https://fonts.googleapis.com/css?family=Amatic+SC");

.flip-container {
  font-family: "Fuzzy Bubbles", cursive !important;
}

::selection {
  background: transparent;
}

.heading-card {
  font-size: 26px;
  font-family: "Amatic SC", cursive !important;
  font-weight: 600;
}

.card {
  color: #013243; /*SHERPA BLUE*/
  position: absolute;
  top: 50%;
  left: 50%;
  width: 350px;
  height: 450px;
  background: #e0e1dc;
  transform-style: preserve-3d;
  transform: translate(-50%, -50%) perspective(2000px);
  box-shadow: inset 300px 0 50px rgba(0, 0, 0, 0.5),
    20px 0 60px rgba(0, 0, 0, 0.5);
  transition: 1s;
}

.card:hover {
  transform: translate(-50%, -50%) perspective(2000px) rotate(15deg) scale(1.2);
  box-shadow: inset 20px 0 50px rgba(0, 0, 0, 0.5),
    0 10px 100px rgba(0, 0, 0, 0.5);
}

.card:before {
  content: "";
  position: absolute;
  top: -5px;
  left: 0;
  width: 100%;
  height: 5px;
  background: #bac1ba;
  transform-origin: bottom;
  transform: skewX(-45deg);
}

.card:after {
  content: "";
  position: absolute;
  top: 0;
  right: -5px;
  width: 5px;
  height: 100%;
  background: #92a29c;
  transform-origin: left;
  transform: skewY(-45deg);
}

.card .imgBox {
  width: 100%;
  height: 100%;
  position: relative;
  transform-origin: left;
  transition: 0.7s;
}

.card .bark {
  position: absolute;
  background: #e0e1dc;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 0.7s;
}

.card .imgBox img {
  min-width: 350px;
  max-height: 450px;
}

.card:hover .imgBox {
  transform: rotateY(-135deg);
}

.card:hover .bark {
  opacity: 1;
  transition: 0.6s;
  box-shadow: 300px 200px 100px rgba(0, 0, 0, 0.4) inset;
}

.card .details {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  padding: 0 0 0 10px;
  z-index: -1;
  margin-top: 30px;
}

.card .details p {
  font-size: 16px;
  transform: rotate(-10deg);
  padding: 0 0 0 10px;
}

.card .details h4 {
  text-align: center;
}

.text-right {
  text-align: right;
}

@media (max-width: 768px) {
  .card {
    color: #013243; /*SHERPA BLUE*/
    position: absolute;
    top: 50%;
    left: 50%;
    width: 270px;
    height: 360px;
    background: #e0e1dc;
    transform-style: preserve-3d;
    transform: translate(-50%, -50%) perspective(2000px);
    box-shadow: inset 300px 0 50px rgba(0, 0, 0, 0.5),
      20px 0 60px rgba(0, 0, 0, 0.5);
    transition: 1s;
  }

  .card:hover {
    transform: translate(-50%, -50%) perspective(2000px) rotate(15deg)
      scale(0.8);
    box-shadow: inset 20px 0 50px rgba(0, 0, 0, 0.5),
      0 10px 100px rgba(0, 0, 0, 0.5);
  }

  .card .imgBox img {
    min-width: 270px;
    max-height: 360px;
  }

  .heading-card {
    font-size: 22px;
  }
  .card .details p {
    font-size: 14px;
  }
}
